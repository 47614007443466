import AgePointCalculation from "@/models/sporteventCalculation/AgePointCalculation";
import BaseService from "../BaseService";

class AgePointCalculationService extends BaseService<AgePointCalculation> {
  public url: string;

  constructor(url = "sportevent-calculation/agepointcalculation/") {
    super(url, "");
    this.url = url;
  }
}

export default new AgePointCalculationService();
